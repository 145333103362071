import {
  Constants,
  MasterPropertyCategories,
  MasterPropertyType,
  ReductionMeasureLists,
} from "constants/constant";
import masterPropertyApi from "middlewares/MasterProperty";
import { ReductionMeasureModel } from "object-models/ReductionMeasures";
import { MasterPropertyModel } from "object-models/shared/master-property.model";
import React, { useEffect, useState } from "react";
import CreateUpdateReductionMeasuresDesign from "./CreateUpdateReductionMeasures.design";
import numericValidator from "services/numericValidator";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import {
  _addReductionMeasure,
  _getReductionMeasure,
  _updateReductionMeasure,
} from "middlewares/ReductionMeasure/ReductionMeasureApi";
import Spinner from "components/Spinner/Spinner";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import { useParams } from "react-router-dom";
import { GHGCarrierModel } from "object-models/GHGCarrierModel";
import { _getOrganization } from "middlewares/OrganizationApi/organization";
import { EFMappingElectricityModel } from "object-models/shared/EFMappingElectricityModel";

interface CreateUpdateReductionMeasuresProps {
  closeDialog: () => void;
  isEdit?: boolean;
  selectedReductionMeasureId: number;
  handleCloseSnackBar: (state: AlertsProps) => void;
  isRefreshEditClose: () => void;
}
export default function CreateUpdateReductionMeasures(
  props: Readonly<CreateUpdateReductionMeasuresProps>
) {
  const { OrganizationId } = useParams();
  const defaultReductionMeasure: ReductionMeasureModel = {
    id: 0,
    orgId: +(OrganizationId ?? "0"),
    reductionMeasureId: 0,
    reductionMeasureName: "",
    businessUnit: "",
    dataSources: "",
    measureStage: "",
    measureCategory: "",
    measureType: "",
    assumptions: "",
    measureDescription: "",
    ghgReduction: "",
    noOfCarrier: 0,
    ghgCarriers: [],
    startYear: undefined,
    endYear: undefined,
    pace: 100,
    execution: 100,
    techLife: 10,
    financialLifetime: 10,
    measureTimingNotes: "",
    easeRating: undefined,
    reputationRating: undefined,
    climateRiskRating: undefined,
    biodiversityRating: undefined,
    communityRating: undefined,
    technologyRedinessRating: undefined,
    operationalDisruptionRating: undefined,
    healthSafetyRating: undefined,
    isActive: false,
    startQuarter: undefined,
    endQuarter: undefined,
    capExInvestment: undefined,
    financingCost: undefined,
    equityComponent: undefined,
    interestRate: undefined,
    financingTerm: undefined,
    opEx: undefined,
    revenue: undefined,
    personnelTime: undefined,
    personnelRate: undefined,
    financialNotes: "",
  };
  const defaultGhgCarrier: GHGCarrierModel = {
    id: 0,
    reductionMeasureId: 0,
    ghgCarrierId: 0,
    annualReduction: 0,
    notes: "",
    isActive: true,
    ghgScope: "",
    ghgCategory: "",
    country: Constants.DefaultCountry,
    region: Constants.DefaultRegion,
  };

  const [businessUnitList, setBusinessUnitList] = useState(
    [] as Array<string | undefined>
  );

  const [loading, setLoading] = useState(false);
  const [energyCategoryList, setEnergyCategoryList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [energyTypeList, setEnergyTypeList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [measureStageList, setMeasureStageList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [measureCategoryList, setMeasureCategoryList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [measureTypeList, setMeasureTypeList] = useState(
    [] as Array<MasterPropertyModel>
  );

  const [ghgCategoryList, setGHGCategoryList] = useState(
    [] as Array<MasterPropertyModel>
  );

  const [gHGScopeList, setGHGScopeList] = useState(
    [] as Array<MasterPropertyModel>
  );

  const [quarterList, setQuarterList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [draftReductionMeasure, setDraftReductionMeasure] = useState(
    defaultReductionMeasure
  );

  const [implementationComplexityList, setImplementationComplexityList] =
    useState([] as Array<MasterPropertyModel>);
  const [reputationList, setReputationList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [climateRiskList, setClimateRiskList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [biodiversityList, setBiodiversityList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [communityList, setCommunityList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [techReadinessList, setTechReadinessList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [opDisruptionList, setOpDisruptionList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [healthSafetyList, setHealthSafetyList] = useState(
    [] as Array<MasterPropertyModel>
  );

  const [countryList, setCountryList] = useState<EFMappingElectricityModel[]>(
    []
  );

  const [regionList, setRegionList] = useState<string[]>([]);

  function fieldChangeHandler(
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    const numericCheckColumns = [
      "pace",
      "execution",
      "techLife",
      "financialLifetime",
      "capExInvestment",
      "financingCost",
      "equityComponent",
      "interestRate",
      "financingTerm",
      "opEx",
      "revenue",
      "personnelTime",
      "personnelRate",
    ];

    if (event.target.name === "ghgReduction") {
      if (
        draftReductionMeasure.ghgReduction !== event.target.value.toString() &&
        draftReductionMeasure.ghgReduction.length > 0
      ) {
        draftReductionMeasure.noOfCarrier = 1;
        draftReductionMeasure.ghgCarriers = [defaultGhgCarrier];
      }
    }
    let newValue;
    if (numericCheckColumns.includes(event.target.name)) {
      if (
        event.target.name === "capExInvestment" ||
        event.target.name === "financingCost" ||
        event.target.name === "opEx" ||
        event.target.name === "revenue"
      ) {
        newValue = numericValidator.allowNumericValue(event.target.value, true);
      } else {
        newValue = numericValidator.allowNumericValue(event.target.value);
      }
    } else {
      newValue = event.target.value;
    }
    setDraftReductionMeasure({
      ...draftReductionMeasure,
      [event.target.name]: newValue,
    });
  }

  const updateProperty = (index: number, name: string, value: string) => {
    if (
      name === "ghgScope" &&
      ghgCategoryList.filter((c) => c.type === value.replace(" ", "")).length <
        1
    ) {
      setLoading(true);
      getGHGCategoryListByScope(value);
    }
    setDraftReductionMeasure((prev) => {
      const updatedGhgCarriers = [...prev.ghgCarriers];
      updatedGhgCarriers[index] = {
        ...updatedGhgCarriers[index],
        [name]:
          name === "annualReduction"
            ? numericValidator.allowNumericValue(value, true)
            : value,
      };
      return { ...prev, ghgCarriers: updatedGhgCarriers };
    });
  };

  function ghgCarrierfieldChangeHandler(
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
    index: number
  ) {
    if (event.target.name == "country") {
      const regions = countryList
        .filter((c) => c.country === event.target.value)
        .map((c) => c.region)
        .filter((region): region is string => region !== undefined);

      setRegionList(regions);
    }
    updateProperty(index, event.target.name, event.target.value);
  }

  useEffect(() => {
    if (
      draftReductionMeasure.noOfCarrier! <
      draftReductionMeasure.ghgCarriers?.length
    ) {
      draftReductionMeasure.ghgCarriers?.splice(
        draftReductionMeasure.noOfCarrier ?? 0,
        draftReductionMeasure.ghgCarriers.length
      );
      setDraftReductionMeasure({ ...draftReductionMeasure });
      return;
    }
    while (
      draftReductionMeasure.noOfCarrier! >
      draftReductionMeasure.ghgCarriers?.length
    ) {
      draftReductionMeasure.ghgCarriers?.push(defaultGhgCarrier);
    }
    setDraftReductionMeasure({ ...draftReductionMeasure });
  }, [draftReductionMeasure.noOfCarrier]);

  async function getReductionMeasureById() {
    _getReductionMeasure(props.selectedReductionMeasureId).then((res) => {
      if (res.ghgCarriers) {
        res.noOfCarrier = res.ghgCarriers.length;
        setDraftReductionMeasure(res);
        getGHGCategoryList();
      }

      setLoading(false);
    });
  }
  function create() {
    
    const formattedData = preprocessReductionMeasure(draftReductionMeasure);
    _addReductionMeasure(formattedData)
      .then((res) => {
        if (res) {
          const message = `${draftReductionMeasure.reductionMeasureName} Created Successfully !`;
          setDraftReductionMeasure(res);
          props.handleCloseSnackBar({
            show: true,
            severity: "success",
            message: message,
          });
          setLoading(false);
          props.closeDialog();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function update() {
    
    const formattedData = preprocessReductionMeasure(draftReductionMeasure);
    _updateReductionMeasure(formattedData)
      .then((res) => {
        if (res) {
          const message = "Data Saved Successfully !";
          setDraftReductionMeasure(res);
          props.handleCloseSnackBar({
            show: true,
            severity: "success",
            message: message,
          });
          setLoading(false);
          props.closeDialog();
          props.isRefreshEditClose();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }
  function onSave() {
   
    if (props.isEdit !== undefined && props.isEdit === true) {
      setLoading(true);
      update();
    } else {
      setLoading(true);
      create();
    }
  }

  function preprocessReductionMeasure(draftReductionMeasure: ReductionMeasureModel) {
    draftReductionMeasure.ghgCarriers.forEach((carrier) => {
      if (carrier.ghgScope !== "Scope2" && carrier.ghgCategory !== "Electricity") {
        carrier.country = Constants.DefaultCountry;
        carrier.region = Constants.DefaultRegion;
      }
    });
  
    draftReductionMeasure.ghgCarriers.forEach((carrier) => {
      carrier.annualReduction = carrier.annualReduction === "" ? 0 : Number(carrier.annualReduction);
    });
  
    return {
      ...draftReductionMeasure,
      techLife: draftReductionMeasure.techLife === "" ? 0 : Number(draftReductionMeasure.techLife),
      financialLifetime: draftReductionMeasure.financialLifetime === "" ? 0 : Number(draftReductionMeasure.financialLifetime),
      personnelTime: draftReductionMeasure.personnelTime === "" ? 0 : Number(draftReductionMeasure.personnelTime),
      personnelRate: draftReductionMeasure.personnelRate === "" ? 0 : Number(draftReductionMeasure.personnelRate),
      revenue: draftReductionMeasure.revenue === "" ? 0 : Number(draftReductionMeasure.revenue),
      opEx: draftReductionMeasure.opEx === "" ? 0 : Number(draftReductionMeasure.opEx),
      capExInvestment: draftReductionMeasure.capExInvestment === "" ? 0 : Number(draftReductionMeasure.capExInvestment),
      financingCost : draftReductionMeasure.financingCost === "" ? 0 : Number(draftReductionMeasure.financingCost),
    };
  }

  async function getReductionMeasureStaticList() {
    masterPropertyApi
      .getMasterPropertyListByCategoryAndType(
        MasterPropertyCategories.Organization,
        MasterPropertyType.Organization.GHGScope
      )
      .then((res) => {
        setGHGScopeList(res);
        setLoading(false);
      });

    setLoading(true);
    masterPropertyApi
      .getMasterPropertyListByCategory(
        MasterPropertyCategories.ReductionMeasure
      )
      .then((res: MasterPropertyModel[]) => {
        if (res.length > 0) {
          setMeasureStageList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.ReductionMeasure.MeasureStage
            )
          );
          setMeasureCategoryList(
            res.filter(
              (prop) =>
                prop.type ===
                MasterPropertyType.ReductionMeasure.MeasureCategory
            )
          );
          setImplementationComplexityList(
            res.filter(
              (prop) =>
                prop.type ===
                MasterPropertyType.ReductionMeasure.MCAImplementationComplexity
            )
          );
          setReputationList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.ReductionMeasure.MCAReputation
            )
          );
          setClimateRiskList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.ReductionMeasure.MCAClimateRisk
            )
          );
          setBiodiversityList(
            res.filter(
              (prop) =>
                prop.type ===
                MasterPropertyType.ReductionMeasure.MCABiodiversity
            )
          );
          setCommunityList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.ReductionMeasure.MCACommunity
            )
          );
          setTechReadinessList(
            res.filter(
              (prop) =>
                prop.type ===
                MasterPropertyType.ReductionMeasure.MCATechRediness
            )
          );
          setOpDisruptionList(
            res.filter(
              (prop) =>
                prop.type ===
                MasterPropertyType.ReductionMeasure.MCAOperationalDisruption
            )
          );
          setHealthSafetyList(
            res.filter(
              (prop) =>
                prop.type ===
                MasterPropertyType.ReductionMeasure.MCAHealthSafety
            )
          );
          setEnergyCategoryList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.ReductionMeasure.EnergyCategory
            )
          );

          setQuarterList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.ReductionMeasure.Quarter
            )
          );
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });

    await fetchOrganizationDetails();
  }

  const fetchOrganizationDetails = async () => {
    try {
      const res = await _getOrganization(Number(OrganizationId));
      if (res) {
        const filteredBusinessUnits = res?.businessUnits
          ?.filter((prop) => prop.name) // Remove undefined names
          ?.map((prop) => prop.name);
        setBusinessUnitList(filteredBusinessUnits ?? []);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  function getMeasureTypeByCategory() {
    if (
      draftReductionMeasure.measureCategory &&
      draftReductionMeasure.measureCategory.length > 0
    ) {
      masterPropertyApi
        .getMasterPropertyListByCategoryAndType(
          MasterPropertyType.ReductionMeasure.MeasureType,
          draftReductionMeasure.measureCategory
        )
        .then((res) => {
          setMeasureTypeList(res);
          setLoading(false);
        });
    }
  }

  function getGHGCategoryList() {
    masterPropertyApi
      .getMasterPropertyListByCategory(MasterPropertyCategories.GHGCategory)
      .then((res) => {
        setGHGCategoryList(res);
        setLoading(false);
      });
  }

  function getGHGCategoryListByScope(ghgScope: string) {
    masterPropertyApi
      .getMasterPropertyListByCategoryAndType(
        MasterPropertyCategories.GHGCategory,
        ghgScope.replaceAll(" ", "")
      )
      .then((res) => {
        const newCatList = ghgCategoryList.concat(res);
        setGHGCategoryList(newCatList);
        setLoading(false);
      });
  }

  function getEnergyTypeList() {
    masterPropertyApi
      .getMasterPropertyListByCategory(MasterPropertyCategories.EnergyType)
      .then((res) => {
        setEnergyTypeList(res);
        setLoading(false);
      });
  }

  function getCountries() {
    masterPropertyApi
      ._getCountries()
      .then((res: EFMappingElectricityModel[]) => {
        if (res) {
          setCountryList(res);
        }
      });
  }

  useEffect(() => {
    setLoading(true);
    getReductionMeasureStaticList();
    if (props.isEdit === true) {
      setLoading(true);
      getReductionMeasureById();
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getMeasureTypeByCategory();
  }, [draftReductionMeasure.measureCategory]);

  useEffect(() => {
    if (
      draftReductionMeasure.ghgReduction ===
      ReductionMeasureLists.EmissionTypeList[0]
    ) {
      getEnergyTypeList();
      getCountries();
    }
  }, [draftReductionMeasure.ghgReduction]);

  return (
    <React.Fragment>
      <CreateUpdateReductionMeasuresDesign
        isEdit={props.isEdit}
        fieldChangeHandler={fieldChangeHandler}
        onSave={onSave}
        closeDialog={props.closeDialog}
        ghgCarrierfieldChangeHandler={ghgCarrierfieldChangeHandler}
        draftReductionMeasure={draftReductionMeasure}
        measureStageList={measureStageList}
        measureCategoryList={measureCategoryList}
        measureTypeList={measureTypeList}
        gHGScopeList={gHGScopeList}
        gHGCategoryList={ghgCategoryList}
        implementationComplexityList={implementationComplexityList}
        reputationList={reputationList}
        climateRiskList={climateRiskList}
        biodiversityList={biodiversityList}
        communityList={communityList}
        techReadinessList={techReadinessList}
        opDisruptionList={opDisruptionList}
        healthSafetyList={healthSafetyList}
        energyCategoryList={energyCategoryList}
        energyTypeList={energyTypeList}
        quarterList={quarterList}
        businessUnitList={businessUnitList}
        countryList={countryList}
        regionList={regionList}
      ></CreateUpdateReductionMeasuresDesign>
      <Spinner size={80} data-testid="spinner" open={loading} />
    </React.Fragment>
  );
}
