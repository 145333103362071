import {
  Grid,
  Typography,
  TextField,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import TitleDefinition from "components/TitleDefinition";
import { CreateUpdateReductionMeasuresDesignProps } from "./data/CreateUpdateReductionMeasuresDesignProps";

import { TitleDefinitionConstants } from "constants/constant";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import OpexComponent from "./OpexComponent";
import { useAppState } from "context/appState.context";

export default function MeasureFinancials(
  props: Readonly<CreateUpdateReductionMeasuresDesignProps>
) {
  const { draftReductionMeasure, fieldChangeHandler } = props;
  const { averageAnnualGrowthRate } = useAppState();
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    // Destructure with default values to avoid "possibly undefined" error
    const {
      equityComponent = 0,
      capExInvestment = 0,
      interestRate = 0,
      financingTerm = 1, // Default to 1 to avoid division by zero
    } = draftReductionMeasure;

    // Convert percentage values to decimal
    const equityComponentDecimal = equityComponent / 100;
    const interestRateDecimal = interestRate / 100;

    // Check if any of the fields are empty or invalid, and clear financing cost if they are
    if (
      !equityComponent &&
      !capExInvestment &&
      !interestRate &&
      !financingTerm
    ) {
      fieldChangeHandler({
        target: {
          name: "financingCost",
          value: "", // Clear the financing cost
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else if (
      equityComponentDecimal >= 0 &&
      interestRateDecimal >= 0 &&
      financingTerm > 0
    ) {
      // Calculate financing cost
      const financingCost = Math.round(
        ((1 - equityComponentDecimal) *
          Number(capExInvestment) *
          interestRateDecimal *
          Math.pow(1 + interestRateDecimal, financingTerm)) /
          Math.pow(1 + interestRateDecimal, financingTerm - 1)
      );

      // Update financing cost field
      fieldChangeHandler({
        target: {
          name: "financingCost",
          value: financingCost.toFixed(0),
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [
    draftReductionMeasure.equityComponent,
    draftReductionMeasure.capExInvestment,
    draftReductionMeasure.interestRate,
    draftReductionMeasure.financingTerm,
  ]);

  return (
    <Grid>
      <Typography
        variant="h6"
        data-testid={"measureReduction"}
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Measure Financials
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography
              variant="body1"
              data-testid={"capexinvestmentlabel"}
              sx={{ mr: 0.5 }}
            >
              CapEx Investment
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <TitleDefinition
              content={
                TitleDefinitionConstants.ReductionMeasure.CapExInvestment
              }
              placement="auto"
            />
          </Stack>
          <TextField
            required
            id="capExInvestment"
            inputProps={{
              maxLength: 12,
              "data-testid": "capExInvestment",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"capExInvestment"}
            value={props.draftReductionMeasure.capExInvestment?? ""}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"equityComponentlabel"}>
              Equity Component (% of Total Investment Cost)
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
          </Stack>
          <TextField
            id="equityComponent"
            required
            inputProps={{
              maxLength: 5,
              "data-testid": "equityComponent",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"equityComponent"}
            value={props.draftReductionMeasure.equityComponent}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"interestRateLabel"}>
              Interest Rate (%)
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
          </Stack>
          <TextField
            id="interestRate"
            required
            inputProps={{
              maxLength: 5,
              "data-testid": "interestRate",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"interestRate"}
            value={props.draftReductionMeasure.interestRate}
            onChange={(e) => {
              props.fieldChangeHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"financingTermLabel"}>
              Financing Term
              <Typography component="span" sx={{ color: "red" }}>
                *
              </Typography>
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.FinancingTerm}
              placement="auto"
            />
          </Stack>
          <TextField
            required
            id="financingTerm"
            inputProps={{
              maxLength: 5,
              "data-testid": "financingTerm",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"financingTerm"}
            value={props.draftReductionMeasure.financingTerm}
            onChange={(e) => {
              props.fieldChangeHandler(e);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"financingcostlabel"}>
              Financing Cost
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.FinancingCost}
              placement="auto"
            />
          </Stack>
          <TextField
            id="financingCost"
            inputProps={{
              maxLength: 12,
              "data-testid": "financingCost",
            }}
            fullWidth
            disabled={true}
            margin="normal"
            size="small"
            name={"financingCost"}
            value={props.draftReductionMeasure.financingCost ?? ""}
            onChange={props.fieldChangeHandler}
            placeholder="this is auto calculated field"
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"opExLabel"}>
              OpEx (no personnel)
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.OpEx}
              placement="auto"
            />
            <Typography
              variant="body2"
              sx={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleOpenDialog}
            >
              OPEX projections
            </Typography>
          </Stack>
          <TextField
            id="opEx"
            inputProps={{
              maxLength: 12,
              "data-testid": "opEx",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"opEx"}
            value={props.draftReductionMeasure.opEx ?? ""}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"revenueLabel"}>
              Annual OpEx
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.Revenue}
              placement="auto"
            />
          </Stack>
          <TextField
            id="revenue"
            inputProps={{
              maxLength: 12,
              "data-testid": "revenue",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"revenue"}
            value={props.draftReductionMeasure.revenue ?? ""}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={""}>
              Personnel Time
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.PersonnelTime}
              placement="auto"
            />
          </Stack>
          <TextField
            id="personnelTime"
            inputProps={{
              maxLength: 5,
              "data-testid": "personnelTime",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"personnelTime"}
            value={props.draftReductionMeasure.personnelTime ?? ""}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Stack direction={"row"}>
            <Typography variant="body1" data-testid={"personnelRateLabel"}>
              Personnel Rate
            </Typography>
            <TitleDefinition
              content={TitleDefinitionConstants.ReductionMeasure.PersonnelRate}
              placement="auto"
            />
          </Stack>
          <TextField
            id="personnelRate"
            inputProps={{
              maxLength: 6,
              "data-testid": "personnelRate",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"personnelRate"}
            value={props.draftReductionMeasure.personnelRate?? ""}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography variant="body1" data-testid={"financialNotesLabel"}>
            Financial Notes
          </Typography>
          <TextField
            type="text"
            id="financialNotes"
            multiline
            rows={2}
            inputProps={{
              flexWrap: "wrap",
              maxLength: 300,
              "data-testid": "financialNotes",
            }}
            fullWidth
            margin="normal"
            size="small"
            name={"financialNotes"}
            value={props.draftReductionMeasure.financialNotes}
            onChange={props.fieldChangeHandler}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          OPEX projections
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <OpexComponent
            startYear={draftReductionMeasure.startYear ?? 2025}
            endYear={draftReductionMeasure.endYear ?? 2050}
            opexValue={draftReductionMeasure.opEx ?? 1}
            growthRate={averageAnnualGrowthRate}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
